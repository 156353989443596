
export let set_new_data=(type,id,data,new_element) =>{
    if(type='phone'){
            for (let i = 0; i < data.length; i++) {
                if(data[i].id==id){
                    data[i]=new_element
                    break;
                }
            }
    }
    return data
 }
export let get_info_user=(user_id,data) =>{
    if(user_id){
    for (let i = 0; i < data.length; i++) {
       if(data[i].user_id==user_id){
          return(
            <>
            <br/><b>{data[i].name}</b><br/>
            <a href={`https://t.me/${data[i].username}`} target="_blank">@{data[i].username}</a>
            </>
          )
       }
    }
  }
    return ''
 }
export let get_new_direction = (where,current_data) => {

   if(where===''){
       return ''
   }
   if(current_data===''){
       return where
   }
   if(current_data===where){
        return where
    }
   if(where.includes('=')){
       let data=where.split('=')
       if(current_data.includes(data[0])){
           let new_where=''
           let divide_by_and=current_data.split('AND')
           for (let i = 0; i < divide_by_and.length; i++) {
                let local_where=''
                let divide_by_equ=divide_by_and[i].split('=')
                if(divide_by_equ[0].includes(data[0])){
                    local_where=divide_by_equ[0]+'='+data[1]
                }
                else{
                    local_where=divide_by_and[i]
                }
                if(i!==divide_by_and.length-1){
                    local_where+=' AND '
                }
                new_where+=local_where
           }
           return new_where
       }
       return current_data+' AND '+where
   }
   if(where.includes('LIKE')){
       let data=where.split('LIKE')
       let new_where=''
       let divide_by_and=current_data.split('AND')
       let changer_flag=0
       for (let i = 0; i < divide_by_and.length; i++) {
           let local_where=''
           if(divide_by_and[i].includes('LIKE')){
           let divide_by_equ=divide_by_and[i].split('LIKE')
           if(divide_by_equ[0].includes(data[0])){
               local_where=divide_by_equ[0]+'LIKE'+data[1]
               changer_flag=1
           }
           else{
               local_where=divide_by_and[i]
           }
           if(i!==divide_by_and.length-1){
               local_where+=' AND '
           }
           new_where+=local_where
           }
           else{
               local_where=divide_by_and[i]
               if(i!==divide_by_and.length-1){
                   local_where+=' AND '
               }
               new_where+=local_where
           }       
       }
       if(changer_flag===0){
           new_where+=' AND '+where
       }
       return new_where 
   }
   return ''
 
}
export let filter_data = (where,old_data,type) => {
    let phones_check=false
    if(where==''){
        return [old_data,where]
    }
    if (old_data[0].hasOwnProperty("key_word")) {
        phones_check=true
    }
    let data=old_data
    where=get_new_direction(where,type)
    let all_keys=where.split('AND')
    for (let y in all_keys) {
      let key=all_keys[y].split('=')
      let key_1=key[0].replaceAll(' ', '')
      let key_2=key[1].replaceAll(' ', '').replaceAll('@', ' ')
      for (let x in data) {
        try {
        if(data[x][`${key_1}`] || data[x][`${key_1}`]===0){
            console.log(data[x][`${key_1}`]+` ${key_2}/${key_2.length} ${(String(data[x][`${key_1}`]).includes(key_2))}`)
            if(key_2.length===0){
                if(y>0 && data[x]['show']===false){
                    data[x]['show']=false
                }
                else{
                    data[x]['show']=true
                }
                continue;
            }
          if(String(data[x][`${key_1}`]).toLowerCase().includes(key_2.toLowerCase())){
            if(y>0 && data[x]['show']===false){
                data[x]['show']=false
            }
            else{
                data[x]['show']=true
            }
            continue;
          }
          else{
            data[x]['show']=false
            continue;
          }
        }
        else{
          data[x]['show']=false
        }
      }
      catch (err) {
        console.log(err)
      }
      }
  }

    return [data,where]
  }
  export  let get_show_items_amount=(data) =>{
    var amount=0
    for (let i = 0; i < data.length; i++) {
       var item=data[i]
       if(item.show){
          amount+=1
       }
    }
    return amount
  }