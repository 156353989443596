
import React from 'react';
import s from './PhonesButtons.module.css';
import { dbAPI } from '../../../api/api_db';
import Add from './Add/Add.jsx'
import Search from './Search/Search.jsx'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Container, Row, Col
} from 'reactstrap';
import DDItem from './DDItem.jsx'
import { get_new_direction,filter_data } from '../../common/common';

const PhonesButtons = React.memo((props) => {
  let get_status = () => {
    if(props.current_type.includes("1")){
      return "Оплаченные"
    }
    if(props.current_type.includes("0")){
      return "Неоплаченные"
    }
    return"ВСЕ"
  }
  return (
    <div className={s.postsBlock}>
      <h3>Phones: {Object.values(props.data).filter(v => v.show === true).length}/{props.data.length}</h3>
      {get_status()}
      <IncreaseForm
      data={props.data} 
      update_data_w={props.update_data_w} 
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}
      order_by={props.order_by}
      
      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}

      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      update_u_senders_w={props.update_u_senders_w}
      unique_senders={props.unique_senders}
      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w}   
      current_type={props.current_type}   
       />
    </div>
  );
});

const IncreaseForm = (props) => {
  let update_data_w_test = (where) => {
    where=get_new_direction(where,props.current_type)
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => {
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        data.push(copy_data)
      }
      props.update_data_w(data,where) 
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_w = (where) => {
    let answer=filter_data(where,props.data,props.current_type)
    props.update_data_w(answer[0],answer[1])
  }

  let get_unique_sections = (where) => {
    dbAPI.get_unique_db_data('phone',where,'section')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].section)
        }
        props.update_u_sections_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let delete_phones=(where) =>{
    dbAPI.db_delete_data(props.db_name,where)        
    .then(result => { 
      if(result.data.status==200){
        update_data_w('')
      }
      else{
        alert(`ERROR: ${result.data.status}`)
      }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
  }
  let get_status= () => {
    if(props.current_type.includes("status=1")){
      return "Активный"
    }
    if(props.current_type.includes("status=0")){
      return "Отключен"
    }
    return""
  }
  let get_theme = () => {
    for (let i = 0; i < props.u_sections.length; i++) {
      if(props.current_type.includes(props.u_sections[i].replaceAll('@', ' '))){
        return props.u_sections[i].replaceAll('@', ' ')
      }
    }
    return""
  }
  let save_sms = (phone) => {
    dbAPI.save_sms(phone)        
    .then(result => { 
      if(result.data.status===200){
      //update_data_w_test('')
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let update_data_api = (json) => {
    dbAPI.update_data(json)        
    .then(result => { 
      if(result.data.status===200){
      update_data_w_test('')
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let delete_phones_by_id = (json) => {
    dbAPI.delete_phones_by_id(json)        
    .then(result => { 
      if(result.data.status===200){
      update_data_w_test('')
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let delete_ph=(selected_data) =>{
    var json={
      'ids':selected_data
    }
    delete_phones_by_id(json)
  }
  let reset_data=(selected_data) =>{
    var json={
      'ids':selected_data,
      'db_name':'phone',
      'db_table':'phone',
      'set':`status=0,active_period=0,owner=0,expiry_date='',order_id='',received_id='',alert_3_days='${false}',alert_1_hour='${false}'`
    }
    update_data_api(json)
  }
  let set_opt_data=(selected_data,owner,received_id,order_id) =>{
    var choosen_date=new Date(new Date().toLocaleString("en-US", {timeZone: 'Europe/Moscow'}))
    var day=choosen_date.getDate().toString().padStart(2, "0")
    var month=(choosen_date.getMonth() + 1).toString().padStart(2, "0")
    var year=choosen_date.getFullYear()
    var hours=(choosen_date.getHours()).toString().padStart(2, "0")
    var minutes=(choosen_date.getMinutes()).toString().padStart(2, "0")
    var expiry_date=`${year}-${month}-${day} ${hours}:${minutes}:06.768591+03:00`
    var json={
      'ids':selected_data,
      'db_name':'phone',
      'db_table':'phone',
      'set':`status=1,active_period=1,owner=${owner},expiry_date='${expiry_date}',order_id='${order_id}',received_id='${received_id}',alert_3_days='${true}',alert_1_hour='${true}'`
    }
    update_data_api(json)
  }
  let run_action= (action,data) => {
    let selected_data=[]
    let selected_phones=[]
    for (let i = 0; i < data.length; i++) {
      var element=data[i]
      if(element.id && element.select===true && element.show===true){
            selected_data.push(element.id)
            selected_phones.push(element.phone)
      }
    }
    if(selected_data.length>0){
      switch (action) {
        case 'delete':
          delete_ph(selected_data)
        case 'reset':
          reset_data(selected_data)
        case 'test_sms':
          save_sms(selected_phones)
        default:
          console.log(`Unknown command: ${action} `)
      }
    }
    else{
      alert('Выберите номера')
    }
  }
  let unique_sections= props.u_sections.map( (p => 
    <DDItem 
    option={p}
    update_data_w={props.update_data_w} 
    db_name={props.db_name}
    order_by={props.order_by}
    update_u_sections_w={props.update_u_sections_w}
    current_type={props.current_type}
    get_new_direction={get_new_direction}
    data={props.data} 
    />) );
  return (
    <div className={s.parent}>
<Container>
<Row>
<Col xs="auto">
  <Add
      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}

      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      update_data_w={props.update_data_w}
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}

      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w} 
      update_u_senders_w={props.update_u_senders_w}
      unique_senders={props.unique_senders}
  />
    <Search
      add_link={props.add_link}
      add_type={props.add_type}
      add_sub_type={props.add_sub_type}
      add_price={props.add_price}
      add_phone_number={props.add_phone_number}
      add_section={props.add_section}
 
      update_add_link_w={props.update_add_link_w}
      update_add_type_w={props.update_add_type_w}
      update_add_sub_type_w={props.update_add_sub_type_w}
      update_add_price_w={props.update_add_price_w}
      update_add_phone_number_w={props.update_add_phone_number_w}
      update_add_section_w={props.update_add_section_w}

      update_data_w={props.update_data_w}
      update_data_w_={props.update_data_w_}
      db_name={props.db_name}

      unique_sub_types={props.unique_sub_types}
      update_u_st_w={props.update_u_st_w}
      u_sections={props.u_sections}
      update_u_sections_w={props.update_u_sections_w} 

      data={props.data}
  />
  <Button color="primary" className={s.serbut} onClick={() => update_data_w_test('')}>🔄</Button>
</Col>
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Статус {get_status()}</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => update_data_w('status=1')}>Активный</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=0')}>Отключен</DropdownItem>
  <DropdownItem onClick={() => update_data_w('status=2')}>Продление</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col> 
<Col xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} onClick={() => get_unique_sections('')} caret>Операторы {get_theme()}</DropdownToggle>
  <DropdownMenu >
  {unique_sections} 
  </DropdownMenu>
  </UncontrolledDropdown>
</Col>
<Col color='danger' xs="auto">
  <UncontrolledDropdown >
  <DropdownToggle className={s.serbut} caret>Команды</DropdownToggle>
  <DropdownMenu >
  <DropdownItem onClick={() => run_action('delete',props.data)}>🧹Удалить</DropdownItem>
  <DropdownItem onClick={() => run_action('reset',props.data)}>🔁Обнулить</DropdownItem>
  <DropdownItem onClick={() => run_action('test_sms',props.data)}>📩Тестовое сообщение</DropdownItem>
  </DropdownMenu>
  </UncontrolledDropdown>
</Col> 
<Col xs="auto">
  <Button color="danger" className={s.serbut} onClick={() => {
    delete_phones("status=0")
    delete_phones("status=1")
    delete_phones("status=2")
    }
    }>🧹🗑️Удалить всё</Button>
</Col>
</Row>
</Container>
    </div>
  )
}


export default PhonesButtons;
