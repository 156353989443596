import React , { useState } from 'react';
import b from './post.module.css';
import  {Button,Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { dbAPI } from '../../../../api/api_db';
import Option from './Option/Option.jsx'
const Add = (props) =>{
  let newLink= React.createRef();
  let newType= React.createRef();
  let newSubType= React.createRef();
  let newPrice= React.createRef();
  let newPhoneNumber= React.createRef();
  let newSection= React.createRef();
  let unique_sub_types= props.unique_sub_types.map( (p => <Option option={p}/>) );
  let unique_sections= props.u_sections.map( (p => <Option option={p}/>) );
  let unique_senders= props.unique_senders.map( (p => <Option option={p}/>) );
  let get_unique_sections = (where) => {
    dbAPI.get_unique_db_data('admin_data|sections',where,'name')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].name)
        }
        props.update_u_sections_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let update_data_w = (where) => {
    dbAPI.get_db_data(props.db_name,where)        
    .then(result => { 
      let data=[]
      for (let i = 0; i < result.data.length; i++) {
        let copy_data=result.data[i]
        copy_data.show=true
        copy_data.page=0
        var u_key_words = []
        for (let y = 0; y < data.length; y++) {
          if(copy_data.phone==data[y].phone){
            if((copy_data.key_word!='fullRent')&&copy_data.show==true){
              copy_data.show=false
            }
            u_key_words.push(data[y].key_word)
          }
        }
        copy_data.u_key_words=[...new Set(u_key_words)]
        data.sort(function(a, b) {
          var keyA = a.phone,
            keyB = b.phone;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });        
        data.push(copy_data)
      }
        props.update_data_w_(data)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let insert_data = (new_data) => {
    dbAPI.insert_phone_db(props.db_name,new_data)        
    .then(result => { 
      if(result.data.status===200){
      update_data_w('')
      //props.update_add_sub_type_w('')
      props.update_add_phone_number_w('')
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let onSubTypeChange=()=>{
    let data=newSubType.current.value;
    data=data;
    props.update_add_sub_type_w(data)
  }
  let onPhoneNumberChange=()=>{
    let data=newPhoneNumber.current.value;
    data=data;
    props.update_add_phone_number_w(data)
  }
  let onSectionChange=()=>{
    let data=newSection.current.value;
    data=data;
    props.update_add_section_w(data)
  }
  let onSenderChange=()=>{
    let data=newPrice.current.value;
    data=data;
    props.update_add_price_w(data)
  }
  let get_unique_item_names = (where) => {
    dbAPI.get_unique_db_data('phone',where,'operator')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].operator)
        }
        props.update_u_st_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  let get_unique_senders = (where) => {
    dbAPI.get_unique_db_data('phone',where,'key_word')        
    .then(result => { 
        var array=[]
        for (let i = 0; i < result.data.length; i++) {
          array.push(result.data[i].key_word)
        }
        props.update_u_senders_w(array)
    })
    .catch(error => { console.error(error); return Promise.reject(error); })
    return 'TEST_DATA'
  }
  const {
    className
  } = props;
  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  }
  let add_phones = (json) => {
    dbAPI.add_phones(json)        
    .then(result => { 
      if(result.data.status===200){
      update_data_w('')
      props.update_add_phone_number_w(``)
    }
    if(result.data.status===400){
      alert(`ERROR TRY AGAIN ${result.data.error}`)
      console.log(result.data)
    }
    })
    .catch(error => { console.error(error); return Promise.reject(error); })  
  }
  let add_new_item=() =>{
    var phones=props.add_phone_number.split('\n')
    var cleaned_phones=[]
    for (let i = 0; i < phones.length; i++) {
      if(phones[i].length>9){
            var phone_number=phones[i]
            if(Array.from(phones[i])[0]=='9'){
              phone_number='+7'+phones[i]
            }
            cleaned_phones.push(phone_number)
      }
    }
    var json={
      'phones':cleaned_phones,
      'operator':props.add_sub_type,
      'section':props.add_section,
      'key_word':props.add_price,
      'status':0,
      'active_period':0,
      'owner':null,
      'expiry_date':'',
      'order_id':'',
      'received_id':''
    }
    add_phones(json)
  }
  return(
    <b>
    <Button className={b.serbut} outline color="primary" onClick={() =>
    {
      get_unique_item_names('');
      get_unique_sections("type='phone'");
      get_unique_senders('');
      toggle()
    }
    }>➕ Добавить номер</Button>
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Новый номер</ModalHeader>
      <ModalBody>
      Оператор:
      <br/>
      <input onChange={onSubTypeChange}
            list="datalist_sub_type"  ref={newSubType}  value={props.add_sub_type} placeholder={"Enter "}/>
      <datalist id="datalist_sub_type">
            <select>
            {unique_sub_types}
            </select>
      </datalist>      
      <br/>
      Номер телефона:
      <br/>
      <textarea class={b.textarea} role="textbox" onChange={onPhoneNumberChange}
              ref={newPhoneNumber}  value={props.add_phone_number} placeholder={"Enter "}/>
      <br/>
      Раздел:
      <br/>
      <input onChange={onSectionChange}
            list="datalist_section"  ref={newSection}  value={props.add_section} placeholder={"Enter "}/>
      <datalist id="datalist_section">
            <select>
            {unique_sections}
            </select>
      </datalist>        
      <br/>
      Реальный отправитель:
      <br/>
      <input onChange={onSenderChange}
            list="datalist_sender"  ref={newPrice}  value={props.add_price} placeholder={"Enter "}/>
      <datalist id="datalist_sender">
            <select>
            {unique_senders}
            </select>
      </datalist>        
      <br/>
      </ModalBody>
      <ModalFooter>
        <Button className={b.serbut} disabled={ !props.add_sub_type || !props.add_phone_number|| !props.add_section || !props.add_price} color="primary" onClick={add_new_item}>Add</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
</b>
)





}


export default Add
